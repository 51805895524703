import './game.scss';
import { CHARS } from '../alphabet';
import { KeyboardEvent, useEffect, useState } from 'react';
import { WORDS } from '../dictionary';
import { Icon } from '@iconify-icon/react';

const randomWord = () => 
  WORDS[Math.floor(Math.random() * WORDS.length)];

export const Game = () => {
  const [word, setWord] = useState<string>(randomWord());
  const [wordProgress, setWordProgress] = useState<number>(0);
  const char = CHARS.find(c => c.latinChar === word[wordProgress])!;
  // const [char, setChar] = useState<CharSchema>(CHARS.find(c => c.latinChar === WORD[0]);
  const [input, setInput] = useState<string>("");
  const [cursor, setCursor] = useState<string>();
  
  const next = () => {
    setInput(input + char.latinChar);
    if (wordProgress + 1 < word.length) {
      setWordProgress(wordProgress + 1);
      return;
    }

    setTimeout(() => {
      setWord(randomWord());
      setWordProgress(0);
      setInput(input => input + " ");
    });
  };

  const skip = () => {
    next();
  };

  const onKey = (e: KeyboardEvent) => {
    e.preventDefault();
    if (e.key.length !== 1) return;
    if (cursor !== undefined) return cursor;
    if (e.key === char.latinChar) {
      next();
      return undefined;
    }
    console.log("wrong, entered:", e.key, ", but correct is:", char.latinChar);

    setCursor(e.key);
    setTimeout(() => {
      setCursor(undefined);
    }, 1000);
  };

  useEffect(() => {
    // @ts-ignore
    document.addEventListener("keydown", onKey);
    return () => {
      // @ts-ignore
      document.removeEventListener("keydown", onKey);
    }
  }, [onKey]);

  return <div className="game">
    <button className='skip' onClick={skip}>
      <Icon icon="ic:round-skip-next" />
      SKIP
    </button>
    <div className='char'>
      {char.char}
    </div>
    <div className='input'>
      {Array.from(input).map((char, index) => 
        <div className='inputChar' key={index}>
          {char}
        </div>
      )}
      {cursor
        ? <span className='cursor'>{cursor}</span>
        : <div className='cursor'></div>}
    </div>
    <input onInput={(e) => onKey(e as unknown as KeyboardEvent)} />
  </div>
};