type Dot = 1 | 0;

export type CharSchema = {
  latinChar: string,
  char: string,
  dots: [
    Dot, Dot,
    Dot, Dot,
    Dot, Dot
  ]
};

export const CHARS: CharSchema[] = [
  { char: '⠁', dots: [1, 0, 0, 0, 0, 0], latinChar: 'a' },
  { char: '⠃', dots: [1, 1, 0, 0, 0, 0], latinChar: 'b' },
  { char: '⠉', dots: [1, 0, 0, 1, 0, 0], latinChar: 'c' },
  { char: '⠙', dots: [1, 0, 0, 1, 1, 0], latinChar: 'd' },
  { char: '⠑', dots: [1, 0, 0, 0, 1, 0], latinChar: 'e' },
  { char: '⠋', dots: [1, 1, 0, 1, 0, 0], latinChar: 'f' },
  { char: '⠛', dots: [1, 1, 0, 1, 1, 0], latinChar: 'g' },
  { char: '⠓', dots: [1, 1, 0, 0, 1, 0], latinChar: 'h' },
  { char: '⠊', dots: [0, 1, 0, 1, 0, 0], latinChar: 'i' },
  { char: '⠚', dots: [0, 1, 0, 1, 1, 0], latinChar: 'j' },
  { char: '⠅', dots: [1, 0, 1, 0, 0, 0], latinChar: 'k' },
  { char: '⠇', dots: [1, 1, 1, 0, 0, 0], latinChar: 'l' },
  { char: '⠍', dots: [1, 0, 1, 1, 0, 0], latinChar: 'm' },
  { char: '⠝', dots: [1, 0, 1, 1, 1, 0], latinChar: 'n' },
  { char: '⠕', dots: [1, 0, 1, 0, 1, 0], latinChar: 'o' },
  { char: '⠏', dots: [1, 1, 1, 1, 0, 0], latinChar: 'p' },
  { char: '⠟', dots: [1, 1, 1, 1, 1, 0], latinChar: 'q' },
  { char: '⠗', dots: [1, 1, 1, 0, 1, 0], latinChar: 'r' },
  { char: '⠎', dots: [0, 1, 1, 1, 0, 0], latinChar: 's' },
  { char: '⠞', dots: [0, 1, 1, 1, 1, 0], latinChar: 't' },
  { char: '⠥', dots: [1, 0, 1, 0, 0, 1], latinChar: 'u' },
  { char: '⠧', dots: [1, 1, 1, 0, 0, 1], latinChar: 'v' },
  { char: '⠺', dots: [0, 1, 0, 1, 1, 1], latinChar: 'w' },
  { char: '⠭', dots: [1, 0, 1, 1, 0, 1], latinChar: 'x' },
  { char: '⠽', dots: [1, 0, 1, 1, 1, 1], latinChar: 'y' },
  { char: '⠵', dots: [1, 0, 1, 0, 1, 1], latinChar: 'z' },
  // { char: '⠂', dots: [0, 1, 0, 0, 0, 0], latinChar: ',' },
  // { char: '⠆', dots: [0, 1, 1, 0, 0, 0], latinChar: ';' },
  // { char: '⠒', dots: [0, 1, 0, 0, 1, 0], latinChar: ':' },
  // { char: '⠲', dots: [0, 1, 0, 0, 1, 1], latinChar: '.' },
  // { char: '⠤', dots: [0, 0, 1, 0, 0, 1], latinChar: '-' },
  // { char: '⠦', dots: [0, 1, 1, 0, 0, 1], latinChar: '?' },
  // { char: '⠴', dots: [0, 0, 1, 0, 1, 1], latinChar: '!' },
  // { char: '⠼', dots: [0, 0, 1, 1, 1, 0], latinChar: '#' }
];

export const getRandomChar = (): CharSchema => {
  const c = CHARS[Math.floor(Math.random() * CHARS.length)];
  return c;
}