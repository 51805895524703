export const WORDS: string[] = [
  "the", "be", "to", "of", "and", "a", "in", "that", "have", "I", "it", "for", "not", "on", "with", "he", "as", "you", "do", "at",
  "this", "but", "his", "by", "from", "they", "we", "say", "her", "she", "or", "an", "will", "my", "one", "all", "would", "there", "their",
  "what", "so", "up", "out", "if", "about", "who", "get", "which", "go", "me", "when", "make", "can", "like", "time", "no", "just", "him",
  "know", "take", "people", "into", "year", "your", "good", "some", "could", "them", "see", "other", "than", "then", "now", "look", "only",
  "come", "its", "over", "think", "also", "back", "after", "use", "two", "how", "our", "work", "first", "well", "way", "even", "new", "want",
  "because", "any", "these", "give", "day", "most", "us", "is", "are", "was", "were", "has", "been", "had", "do", "does", "did", "here", "just",
  "than", "but", "before", "after", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten", "may", "might", "must", "can",
  "could", "should", "would", "shall", "will", "upon", "into", "onto", "out", "up", "down", "about", "above", "below", "through", "across", "along",
  "against", "with", "without", "within", "throughout", "among", "between", "behind", "before", "after", "during", "since", "for", "from", "to",
  "as", "at", "by", "in", "of", "on", "off", "over", "under", "until", "up", "down", "out", "in", "to", "into", "from", "off", "on", "away",
  "back", "forward", "out", "around", "round", "through", "over", "under", "above", "below", "beside", "behind", "between", "among", "within",
  "without", "with", "near", "far", "here", "there", "where", "when", "while", "before", "after", "during", "since", "for", "ago", "till", "until",
  "just", "already", "still", "yet", "ever", "never", "always", "sometimes", "often", "usually", "rarely", "seldom", "sometimes", "always", "together",
  "apart", "around", "about", "about", "above", "below", "across", "along", "alongside", "against", "upon", "onto", "off", "on", "on", "through", "into",
  "into", "with", "without", "within", "outside", "beneath", "underneath", "among", "amid", "amidst", "amongst", "behind", "before", "between", "beyond",
  "above", "over", "under", "beside", "next", "last", "first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth", "ninth", "tenth",
  "hundred", "thousand", "million", "billion", "trillion", "gazillion", "zillion", "hundredth", "thousandth", "millionth", "billionth", "trillionth",
  "gazillionth", "zillionth", "point", "comma", "semicolon", "colon", "question", "exclamation", "quote", "doublequote", "singlequote", "bracket",
  "parenthesis", "curly", "brace", "angle", "slash", "backslash", "underscore", "dash", "hyphen", "period", "dot", "comma", "semicolon", "colon",
  "apostrophe", "asterisk", "star", "ampersand", "at", "hash", "pound", "percent", "dollar", "euro", "yen", "won", "rupee", "dinar", "pound",
  "penny", "cent", "dime", "nickel", "quarter", "dollar", "money", "cash", "payment", "check", "card", "credit", "debit", "deposit", "withdraw",
  "transfer", "bill", "note", "coin", "currency", "wallet", "purse", "bank", "account", "credit", "union", "loan", "interest", "rate", "fee", "charge",
  "fine", "penalty", "tax", "refund", "rebate", "discount", "sale", "coupon", "voucher", "reward", "gift", "present", "bonus", "prize", "jackpot",
  "winner", "loser", "champion", "runnerup", "contest", "game", "competition", "tournament", "race", "match", "event", "battle", "fight", "war",
  "conflict", "struggle", "siege", "combat", "combatant", "soldier", "army", "troop", "force", "navy", "airforce", "marines", "coastguard", "special",
  "ops", "mission", "task", "objective", "goal", "aim", "purpose", "target", "destination", "direction", "route", "path", "road", "street", "highway",
  "lane", "avenue", "boulevard", "drive", "court", "plaza", "circle", "square", "park", "place", "field", "garden", "yard", "backyard", "frontyard",
  "playground", "schoolyard", "house", "home", "apartment", "condo", "flat", "townhouse", "villa", "mansion", "castle", "palace", "hut", "cabin",
  "lodge", "shed", "shack", "bungalow"
];